/* @font-face {
    font-family: 'ProzaLibre-regular';
    src:url(/public/assets/css/font/ProzaLibre-Regular.ttf);
}

@font-face {
    font-family: 'ProzaLibre-bold';
    src:url(/public/assets/css/font/ProzaLibre-Bold.ttf);
}

@font-face {
    font-family: 'ProzaLibre-medium';
    src:url(/public/assets/css/font/ProzaLibre-Medium.ttf);
}

@font-face {
    font-family: 'ProzaLibre-semi';
    src:url(/public/assets/css/font/ProzaLibre-SemiBold.ttf);
} */
    

.new-listing-sec {
    margin-top: 4%;
    display: flex;
    width: 100%;
}

.new-listing-left-sec {
    width: 50%;
    height: 461px;
    left: 105px;
    top: 187px;
    background: #1DD05D;
    border-radius: 20px;

    padding: 4em;
  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.new-listing-right-sec {
    width: 50%;
    margin: auto;
    /* background-color: #f9fafc; */
}

.new-listing-logo-sec img {
    max-width: 3em;
}

.new-listing-header-sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2em;
    gap: 1em;
    border-bottom: 1px solid #e6e6e6;
}

.new-listing-btn {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #222222 !important;
    padding: 10px 20px !important;
    background: #fff !important;
    box-shadow: 0px 0px 0px 1px #e6e6e6 inset, 0px 2px 4px rgb(0 0 0 / 18%) !important;
    display: inline-flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    position: relative !important;
    display: -webkit-inline-box !important;
    display: -moz-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    align-items: center !important;
    border: none !important;
    font-weight: 600 !important;
    border-radius: 6px;
    cursor: pointer !important;
    -webkit-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    transform: scale(1) !important;
    -webkit-transition: -webkit-transform 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease !important;
    -moz-transition: transform 0.2s ease, box-shadow 0.2s ease !important;
    transition: -ms-transform 0.2s ease, -webkit-transform 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
}

.new-listing-normal-btn {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #222222 !important;
    padding: 10px 20px !important;
    background: #e6e6e6 !important;
    display: inline-flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    position: relative !important;
    display: -webkit-inline-box !important;
    display: -moz-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    align-items: center !important;
    border: none !important;
    font-weight: 600 !important;
    border-radius: 6px;
    cursor: pointer !important;
    -webkit-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    transform: scale(1) !important;
    -webkit-transition: -webkit-transform 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease !important;
    -moz-transition: transform 0.2s ease, box-shadow 0.2s ease !important;
    transition: -ms-transform 0.2s ease, -webkit-transform 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
}

.new-listing-left-sec .new-listing-content-sec h2 {
    font-size: 4em;
    font-weight: 600;
    color: var(--white);
    margin-bottom: 0;
}

.new-listing-body-wrapper {
    overflow: auto;
    padding: 2em;
}

.new-listing-footer-btn-sec {
    padding: 2em 0em 0em 0em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-listing-footer-finished-btn-sec {
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.new-listing-footer-btn-sec .next-btn {
    display: inline-block;
    padding: 0.65em 1.8em;
    color: #fff !important;
    font-size: 1.2em;
    background-color: #1DD05D;
    font-family: "Cereal-Medium";
    text-transform: capitalize;
    border: 0;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px !important;
    -moz-box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px !important;
    -webkit-box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px !important;
    transition: 0.3s all linear;
    text-align: center;
}

.new-listing-footer-finished-btn-sec .next-btn {
    display: inline-block;
    padding: 0.65em 1.8em;
    color: #fff !important;
    font-size: 1.2em;
    background-color: #f26422;
    font-family: "Cereal-Medium";
    text-transform: capitalize;
    border: 0;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px !important;
    -moz-box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px !important;
    -webkit-box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px !important;
    transition: 0.3s all linear;
    text-align: center;
}

.new-listing-footer-btn-sec .back-btn {
    background-color: transparent !important;
    text-decoration: underline;
    font-size: 1.2em;
    border: 0;
    font-family: "Cereal-Medium";
    text-transform: capitalize;
}


.new-listing-category-item input[type=radio] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.new-listing-category-item input[type=radio]:checked+.new-listing-category-card {
    border-color: var(--primary-color);
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}


.new-listing-category-card {
    padding: 1em;
    border: 1px solid #e6e6e6;
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgb(58 196 125 / 3%), 0 0.9375rem 1.40625rem rgb(58 196 125 / 3%), 0 0.25rem 0.53125rem rgb(58 196 125 / 5%), 0 0.125rem 0.1875rem rgb(58 196 125 / 3%);
}

.new-listing-category-box {
    grid-template-columns: repeat(1, 1fr);
    gap: 1em;
    display: grid;
}

.new-listing-category-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-host-img {
    height: 4em;
    border-radius: 6px;
    object-fit: cover;
}

.new-listing-category-info h4 {
    font-size: 1.2em;
    margin-bottom: 0;
    font-family: "Cereal-Medium";
    text-transform: capitalize;
    color: #000;
}


.new-listing-sub-category-item input[type=radio] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.new-listing-sub-category-item input[type=radio]:checked+.new-listing-sub-category-card {
    border-color: var(--primary-color);
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}

.new-listing-sub-category-card {
    padding: 2em;
    border: 1px solid #e6e6e6;
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgb(58 196 125 / 3%), 0 0.9375rem 1.40625rem rgb(58 196 125 / 3%), 0 0.25rem 0.53125rem rgb(58 196 125 / 5%), 0 0.125rem 0.1875rem rgb(58 196 125 / 3%);
}

.new-listing-sub-category-box {
    grid-template-columns: repeat(1, 1fr);
    gap: 1em;
    display: grid;
}

.new-listing-sub-category-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5em;
}

.new-listing-sub-category-info h4 {
    font-size: 1.2em;
    margin-bottom: 0;
    font-family: "Cereal-Medium";
    text-transform: capitalize;
}

.new-listing-sub-category-info p {
    color: #717171;
    font-size: 1em;
    margin-bottom: 0;
    text-transform: none;
}

.new-listing-property-form-box {
    grid-template-columns: repeat(1, 1fr);
    gap: 1em;
    display: grid;
}

.new-listing-property-form-card {
    grid-template-columns: 130px auto;
    gap: 1em;
    display: grid;
}

.new-listing-property-form-card label {
    font-size: 1.2em;
    margin-bottom: 0;
    /* font-family: "Cereal-Medium"; */
    text-transform: none;
    margin-top: 0.4em;
}

.new-listing-property-form-box {

    background: #FFFFFF;
    box-shadow: 8px 10px 30px rgba(0, 95, 34, 0.2);
    border-radius: 10px;
    padding: 2em;
    border: 1px solid #e6e6e6;
    width: 100%;
}

.new-listing-property-dimensions-item input[type=radio] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.new-listing-property-dimensions-item input[type=radio]:checked+.new-listing-property-dimensions-card {
    border-color: var(--primary-color);
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}

.new-listing-property-dimensions-card {
    padding: 0.5em;
    border: 1px solid #e6e6e6;
    font-size: 1.1em !important;
    width: 100%;
    cursor: pointer;
    border-radius: .25rem;
    text-align: center;
    margin-bottom: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgb(58 196 125 / 3%), 0 0.9375rem 1.40625rem rgb(58 196 125 / 3%), 0 0.25rem 0.53125rem rgb(58 196 125 / 5%), 0 0.125rem 0.1875rem rgb(58 196 125 / 3%);
}

.new-listing-property-form-box .form-control {
    border: 1px solid;
    width: 100%;
    border-radius: .25rem;
    margin-bottom: 0;
    font-size: 1.1em;
    font-weight: 400;
    /* height: 46px; */
    padding: 0.6em 0.6em;
    border-color: #aaa;
    color: #484848;
    text-transform: none !important;
    /* box-shadow: 0 0.46875rem 2.1875rem rgb(58 196 125 / 3%), 0 0.9375rem 1.40625rem rgb(58 196 125 / 3%), 0 0.25rem 0.53125rem rgb(58 196 125 / 5%), 0 0.125rem 0.1875rem rgb(58 196 125 / 3%); */
}

.new-listing-property-form-box .form-control::placeholder {
    font-size: 0.9em;
    text-transform: none !important;
    font-weight: 400;
}

.new-listing-property-form-card .react-numeric-input b {
    background-color:#1DD05D !important;
}

.new-listing-property-form-card .react-numeric-input i {
    background: var(--white) !important;
}

.new-listing-property-form-card .react-numeric-input {
    width: 75%;
}

.new-listing-property-form-card .react-numeric-input .form-control {
    height: auto !important;
    padding: 0.375rem 0.75rem;
}

.new-listing-property-form-card textarea.form-control {
    height: auto !important;
}

.new-listing-property-dimensions-box {
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    display: grid;
    margin-top: 1em;
}

.new-listing-upload-img-box {
    padding: 2em;
    border: 1px solid #e6e6e6;
    width: 100%;
    border-radius: 0px;
}

.new-listing-upload-img-preview {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    display: grid;
    margin-top: 2em;
}

.new-listing-upload-preview-img {
    height: 15em;
    width: 100%;
    margin: auto;
    border-radius: 6px;
    object-fit: cover;
    position: relative;
    z-index: 2;
}

.new-listing-amenities-box {
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    display: grid;
}

.new-listing-amenities-card {
    padding: 2em;
    border: 1px solid #e6e6e6;
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 0;
    height: 100%;
    box-shadow: 0 0.46875rem 2.1875rem rgb(58 196 125 / 3%), 0 0.9375rem 1.40625rem rgb(58 196 125 / 3%), 0 0.25rem 0.53125rem rgb(58 196 125 / 5%), 0 0.125rem 0.1875rem rgb(58 196 125 / 3%);
}


.new-listing-amenities-item input[type=checkbox] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.new-listing-amenities-item input[type=checkbox]:checked+.new-listing-amenities-card {
    border-color: var(--primary-color);
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}

.new-listing-amenities-info {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.amenities-host-icon {
    height: 2.2em;
    object-fit: cover;
}

.new-listing-amenities-info h4 {
    font-size: 1.1em;
    margin-bottom: 0;
    text-transform: none !important;
    text-align: center;
    line-height: 1.5;
}

.add-listing-success-img-sec {
    padding: 2em;
    border: 1px solid #e6e6e6;
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 0;
    height: 100%;
    box-shadow: 0 0.46875rem 2.1875rem rgb(58 196 125 / 3%), 0 0.9375rem 1.40625rem rgb(58 196 125 / 3%), 0 0.25rem 0.53125rem rgb(58 196 125 / 5%), 0 0.125rem 0.1875rem rgb(58 196 125 / 3%);
}

.add-listing-success-img {
    max-width: 20em;
    margin: auto;
}

.add-listing-success-img-sec h4 {
    font-size: 1.1em;
    margin-bottom: 0;
    text-transform: none !important;
    text-align: center;
    line-height: 1.5;
    margin-top: 2em;
}

.new-listing-sec ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.new-listing-sec ::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.new-listing-sec ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);

    background-image: -webkit-gradient(linear, 0 0, 0 100%,
        color-stop(.5, rgba(255, 255, 255, .2)),
        color-stop(.5, transparent), to(transparent));
}

.new-listing-property-form-card-1 .new-map{
    height:400px!important;
    width: 100%!important;
    position: relative!important;
}

.remove-new-listing-upload-preview-icon{
    width: 2.2em;
}
.new-listing-upload-img-preview-card{
    position: relative;
    text-align: center;
}

.new-listing-upload-img-preview-card::before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    border-radius: 6px;
    /* transform: rotate(180deg); */
    z-index: 1;
}

.remove-new-listing-upload-preview-icon-sec{
    position: absolute;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
}

.remove-new-listing-upload-preview-icon-sec{
    background-color: transparent!important;
    padding: 0!important;
    border: 0!important;
}

.new-amenties-card li{
    padding: 1em;
    border: 1px solid #e6e6e6;
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 0;
    height: 100%;
    /* box-shadow: 0 0.46875rem 2.1875rem rgb(58 196 125 / 3%), 0 0.9375rem 1.40625rem rgb(58 196 125 / 3%), 0 0.25rem 0.53125rem rgb(58 196 125 / 5%), 0 0.125rem 0.1875rem rgb(58 196 125 / 3%);     */
}

.new-amenties-item{
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    display: grid;
    padding-bottom: 0.5em;  
}

.new-amenties-item .amentieimg{
    margin-right: 0;
    margin-bottom: 0.5em;
    height: 25px;
    object-fit: contain;
}

.home-requirements{
    padding: 0.5em 1em;
    /* border: 1px solid #e6e6e6; */
    border:0;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}

.home-requirements li{
    padding-left: 1em;
    margin: 0;
    display: flex;
    align-items: center;
    gap:0.5em;
}

.home-requirements li:first-child{
    padding-left: 0;
}

.home-requirements li:last-child{
    padding-right: 0;
    border-right: 0;
}
.tr {
    border: 0;
    display: block;
    margin: 5px;
}
.solid {
    border: 2px red rgba(0, 95, 34, 0.2);
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 8px 10px 30px rgba(0, 95, 34, 0.2);
}

